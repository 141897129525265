<template>
  <div class="rounded p-3 mb-3" v-background-3>
    <h4>Motifs de recours aux CDD</h4>
    <div class="p-3">
      <table class="table table-responsive-lg border" v-background-3 v-color-1>
        <thead>
          <tr>
            <th scope="col" class="text-muted">Evaluation sur {{ contratsCdd.total }} contrats</th>
            <th scope="col">Homme</th>
            <th scope="col">Femme</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-left">
            <td class="text-left" scope="row">CDD Activité</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddHActivite }}</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddFActivite }}</td>
          </tr>
          <tr class="text-left">
            <td class="text-left" scope="row">CDD Alternance</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddHAlternance }}</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddFAlternance }}</td>
          </tr>
          <tr class="text-left">
            <td class="text-left" scope="row">CDD Remplacement</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddHRemplacement }}</td>
            <td class="text-left" scope="row">{{ contratsCdd.contratsCddFRemplacement }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: ["collaborateursData", "collaborateursLastContrats"],
  computed: {
    contratsCdd() {
      const contrats = this.collaborateursLastContrats;
      const contratsCddH = contrats.filter(
        (contrat) => contrat.contrat_type_id == 2 && contrat.collaborateur_sexe_id == 1
      );
      const contratsCddF = contrats.filter(
        (contrat) => contrat.contrat_type_id == 2 && contrat.collaborateur_sexe_id == 2
      );

      const contratsCddHActivite = contratsCddH.filter((contrat) => contrat.cdd_activite == 1);
      const contratsCddHAlternance = contratsCddH.filter((contrat) => contrat.cdd_alternance == 1);
      const contratsCddHRemplacement = contratsCddH.filter((contrat) => contrat.cdd_remplacement == 1);

      const contratsCddFActivite = contratsCddF.filter((contrat) => contrat.cdd_activite == 1);
      const contratsCddFAlternance = contratsCddF.filter((contrat) => contrat.cdd_alternance == 1);
      const contratsCddFRemplacement = contratsCddF.filter((contrat) => contrat.cdd_remplacement == 1);

      return {
        contratsCddHActivite: contratsCddHActivite.length,
        contratsCddHAlternance: contratsCddHAlternance.length,
        contratsCddHRemplacement: contratsCddHRemplacement.length,
        contratsCddFActivite: contratsCddFActivite.length,
        contratsCddFAlternance: contratsCddFAlternance.length,
        contratsCddFRemplacement: contratsCddFRemplacement.length,
        total: contratsCddH.length + contratsCddF.length,
      };
    },
  },
};
</script>

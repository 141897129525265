var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"background-3",rawName:"v-background-3"}],staticClass:"rounded p-3 mb-3"},[_c('h6',[_vm._v("Effectifs")]),_c('base-select',{staticClass:"mb-1",attrs:{"inputText":"Choisir dans la liste","options":[
      { id: 0, description: 'Effectif total' },
      { id: 1, description: 'Effectif par age' },
      { id: 2, description: 'Effectif par sexe' },
      { id: 3, description: 'Effectif par qualification' },
      { id: 4, description: 'Effectif par type de contrat' },
      { id: 5, description: 'Effectif handicapés' },
      { id: 6, description: 'Ancienneté' } ],"modeIOS":true},on:{"input":function($event){_vm.filter2_id = 0}},model:{value:(_vm.filter_id),callback:function ($$v) {_vm.filter_id=_vm._n($$v)},expression:"filter_id"}}),(_vm.filter_id !== 2)?_c('base-select',{attrs:{"inputText":"Filter par H/F","options":[
      { id: 0, description: 'Tous' },
      { id: 1, description: 'Hommes' },
      { id: 2, description: 'Femmes' } ],"modeIOS":true},model:{value:(_vm.filter2_id),callback:function ($$v) {_vm.filter2_id=_vm._n($$v)},expression:"filter2_id"}}):_vm._e(),_c('apexchart',{ref:"chart",attrs:{"type":"area","height":"460","options":_vm.chartOptions,"series":_vm.series.data}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="rounded p-3 mb-3" v-background-3>
      <h4>Temps partiel</h4>
      <div class="p-3">
        <table class="table table-responsive-lg border" v-background-3 v-color-1>
          <thead>
            <tr>
              <th scope="col" class="text-muted">Total {{ collaborateursFiltered.length }}</th>
              <th scope="col">Effectif</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-left">
              <td class="text-left" scope="row">Temps partiel (H)</td>
              <td>{{ tempsPartiel.tempsPartielH }}</td>
            </tr>
            <tr class="text-left">
              <td class="text-left" scope="row">Temps partiel (F)</td>
              <td>{{ tempsPartiel.tempsPartielF }}</td>
            </tr>
            <tr class="text-left" v-for="statut in collaborateursData.contratStatus" :key="statut.id">
              <td class="text-left" scope="row">Temps partiel ({{ statut.description }})</td>
              <td>{{ tempsPartiel[statut.id] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: ["collaborateursData", "collaborateursLastContrats"],
  computed: {
    collaborateursFiltered() {
      return this.collaborateursLastContrats.filter(
        (collab) => /*collab.contrat_siret.siret_societe_id === 1 &&*/ this.$moment(collab.date_effet) <= this.$moment()
      );
    },

    tempsPartiel() {
      let tempsPartielH = 0;
      let tempsPartielF = 0;
      let tempsPartielTypeContrat = [];
      const collabs = this.collaborateursFiltered;

      if (this.collaborateursData && this.collaborateursData.contratStatus) {
        this.collaborateursData.contratStatus.forEach((statut) => {
          tempsPartielTypeContrat[statut.id] = 0;
        });

        if (collabs) {
          const collabsPartiels = collabs.filter((dd) => dd.contrat_temps_partiel == true);
          if (collabsPartiels && collabsPartiels.length > 0)
            collabsPartiels.forEach((collabPartiel) => {
              if (collabPartiel.collaborateur_sexe_id == 1) tempsPartielH++;
              if (collabPartiel.collaborateur_sexe_id == 2) tempsPartielF++;

              this.collaborateursData.contratStatus.forEach((statut) => {
                if (collabPartiel.contrat_statut_id == statut.id) tempsPartielTypeContrat[statut.id]++;
              });
            });
        }
      }
      return {
        tempsPartielH: tempsPartielH,
        tempsPartielF: tempsPartielF,
        ...tempsPartielTypeContrat,
      };
    },
  },
};
</script>

<template>
  <div>
    <select v-model="selectedSocieteId" class="form-control w-auto" id="societe_id" @change="emitGetCollaborateursAll">
        <option disabled value="" selected>Sélectionnez une société</option>
        <option value="1" v-if="havePermission('manage_cse')">Energy</option>
        <option value="2" v-if="havePermission('manage_cse_techno')">Techno</option>
        <option value="13" v-if="havePermission('manage_cse_hoggar')">Hoggar</option>
      </select>
    <rh ref="rhComponent"></rh>
    <Formation ref="formationComponent"></Formation>
  </div>
</template>
<script>
import Rh from "@/components/cse/Rh.vue";
import Formation from "@/components/cse/Formation.vue";

import { mapGetters } from "vuex";

export default {
  components: { Rh, Formation },
  data() {
    return {
      selectedSocieteId: "",
    };
  },
  methods: {
    emitGetCollaborateursAll() {
      this.$refs.rhComponent.getCollaborateursAll(this.selectedSocieteId);
      this.$refs.formationComponent.getFormationsAll(this.selectedSocieteId);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
  },
};
</script>

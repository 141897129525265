<template>
  <div class="rounded p-3 mb-3" v-background-3>
    <h6>Salaires moyens</h6>

    <base-select
      class="mb-1"
      inputText="Choisir dans la liste"
      v-model.number="filter_id"
      :options="collaborateursData.contratStatus"
      :modeIOS="true"
      :disabled="false"
    ></base-select>
    <apexchart ref="chart" type="area" height="460" :options="chartOptions" :series="series.data"></apexchart>
  </div>
</template>

<script>
import BaseSelect from "@/components/bases/Select.vue";

export default {
  data() {
    return {
      //series: [],
      labels: [],
      filter_id: null,
      nbre_contrat: 0,
    };
  },
  components: { BaseSelect },

  props: ["collaborateurs", "collaborateursData"],
  methods: {},
  mounted() {},
  computed: {
    series() {
      let debut = this.$moment().endOf("month").subtract(2, "year");
      let fin = this.$moment().endOf("month");
      let dataH = [];
      let dataF = [];
      let dataQualification = [];
      let dataTypeContrat = [];
      let labels = [];

      if (!this.collaborateursData) return {};

      if (this.collaborateursData && this.collaborateursData.contratStatus)
        this.collaborateursData.contratStatus.forEach((statut) => {
          dataQualification.push({
            statut_id: statut.id,
            name: statut.description,
            data: [],
          });
        });

      if (this.collaborateursData && this.collaborateursData.contratTypes)
        this.collaborateursData.contratTypes.forEach((type) => {
          dataTypeContrat.push({
            type_id: type.id,
            name: type.description,
            data: [],
          });
        });

      let collabs = this.collaborateurs;

      while (debut < fin) {
        let salaireH = 0;
        let nbre_contratH = 0;
        let salaireF = 0;
        let nbre_contratF = 0;

        if (collabs && collabs.length > 0)
          collabs.forEach((collab) => {
            let versionsH = [];
            let versionsF = [];

            if (collab && collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length > 0)
              collab.collaborateur_contrats_all.forEach((contrat) => {
                if (
                  /*!contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== 1) &&*/
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
                ) {
                  contrat.contrat_version_all.forEach((version) => {
                    if (/*version.contrat_siret.siret_societe_id === 1 && */this.$moment(version.date_effet) <= debut) {
                      if (collab.collaborateur_sexe_id == 1) versionsH.push(version);
                      if (collab.collaborateur_sexe_id == 2) versionsF.push(version);

                      /*if (version.contrat_statut_id == this.filter_id) {
                        console.log(debut.format("MMMM YYYY") +";"+collab.collaborateur_nom + ";" + collab.collaborateur_prenom + ";" + version.contrat_brut_annuel + ";" + version.contrat_statut_id)
                      }*/

                    }
                  });
                }
              });

            //versionsH = versionsH.filter((v) => v.contrat_statut_id == this.filter_id);
            //versionsF = versionsF.filter((v) => v.contrat_statut_id == this.filter_id);

            if (versionsH.length > 0) {
              if (
                versionsH[versionsH.length - 1] &&
                versionsH[versionsH.length - 1].contrat_brut_annuel &&
                versionsH[versionsH.length - 1].contrat_statut_id == this.filter_id
              ) {
                salaireH = salaireH + parseFloat(versionsH[versionsH.length - 1].contrat_brut_annuel);
                nbre_contratH++;
              }
              if (
                versionsH[versionsH.length - 1] &&
                versionsH[versionsH.length - 1].contrat_brut_annuel &&
                this.filter_id == null
              ) {
                salaireH = salaireH + parseFloat(versionsH[versionsH.length - 1].contrat_brut_annuel);
                nbre_contratH++;
              }
            }
            if (versionsF.length > 0) {
              if (
                versionsF[versionsF.length - 1] &&
                versionsF[versionsF.length - 1].contrat_brut_annuel &&
                versionsF[versionsF.length - 1].contrat_statut_id == this.filter_id
              ) {
                salaireF = salaireF + parseFloat(versionsF[versionsF.length - 1].contrat_brut_annuel);
                nbre_contratF++;
              }
              if (
                versionsF[versionsF.length - 1] &&
                versionsF[versionsF.length - 1].contrat_brut_annuel &&
                this.filter_id == null
              ) {
                salaireF = salaireF + parseFloat(versionsF[versionsF.length - 1].contrat_brut_annuel);
                nbre_contratF++;
              }
            }
          });

        dataH.push((salaireH / nbre_contratH).toFixed(0));
        dataF.push((salaireF / nbre_contratF).toFixed(0));

        const nbrTotal = nbre_contratH + nbre_contratF;

        labels.push(debut.format("MMMM YYYY") + " (" + nbrTotal + ")");
        debut.add(1, "month").endOf("month");
      }

      return {
        labels: labels,
        data: [
          { name: "Salaire H", data: dataH, color: "#007bff" },
          { name: "Salaire F", data: dataF, color: "#dc3545" },
        ],
      };
    },
    chartOptions() {
      if (!this.collaborateurs) return {};
      return {
        colors: ["#EB9D00"],
        chart: {
          background: "transparent",
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
        },
        stroke: {
          width: 4,
          curve: "smooth",
        },
        xaxis: {
          categories: this.series.labels,
        },
        fill: {
          opacity: 0.8,
        },
        markers: {
          size: 4,
          strokeColors: "#fff",
          strokeWidth: 0,
          hover: {
            size: 7,
          },
        },
        legend: {
          show: true,
          position: "right",
          showForZeroSeries: true,
          itemMargin: {
            horizontal: 0,
            vertical: 3,
          },
          markers: {
            width: 10,
            height: 10,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "€";
            },
          },
        },
      };
    },
  },
};
</script>
